<template>
  <div class="container">
    <div class="card text-center" v-if="isOrderLoading">
      <div class="card-header">...</div>
      <div class="card-body">
        <b-icon icon="three-dots" animation="cylon" font-scale="4"></b-icon>
      </div>
    </div>
    <template v-else>
      <b-overlay :show="showOverlay" rounded="sm">
        <div class="card card-custom example example-compact">
          <div class="card-header">
            <h3 class="card-title">
              {{ $t("PAGES.SALES_MANAGEMENT.ORDER_DETAILS.SALE_DETAILS") }}
            </h3>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>

          <form class="form">
            <div class="card-body">
              <div class="row px-5">
                <div class="col-12 col-md-6">
                  <h6>
                    {{ $t("PAGES.SALES_MANAGEMENT.ORDER_DETAILS.ORDER_INFOS") }}
                  </h6>
                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.ORDER_DETAILS.ORDER_ID")
                      }}:</label
                    >
                    <span class="ml-2">{{
                      viewOrder.orderNumber ? viewOrder.orderNumber : null
                    }}</span>
                  </div>

                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_AMOUNT")
                      }}:</label
                    >
                    <span class="ml-2"
                      >{{ viewOrder.price }}
                      {{ getPaymentUnitName(viewOrder) | upperCase }}</span
                    >
                  </div>

                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE")
                      }}:</label
                    >
                    <span class="ml-2">{{ viewOrder.fee }}%</span>
                  </div>

                  <div class="col-12">
                    <label class="col-form-label font-weight-bold"
                      >{{ $t("PAGES.SALES_MANAGEMENT.LIST.TOTAL") }}:</label
                    >
                    <span class="ml-2"
                      >{{ getTotal(viewOrder) }}
                      {{ getPaymentUnitName(viewOrder) | upperCase }}</span
                    >
                  </div>
                </div>

                <div class="col-12 col-md-6">
                  <h6>
                    {{
                      $t(
                        "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.CUSTOMER_INFO"
                      )
                    }}
                  </h6>
                  <div class="d-inline-flex col-12 align-items-center">
                    <label class="col-form-label font-weight-bold">
                      {{ viewOrder.customerName }}
                    </label>
                  </div>

                  <div class="roe">
                    <div
                      class="d-inline-flex col-12 col-md-6 align-items-center"
                      v-if="viewOrder.customerPhone"
                    >
                      <i class="menu-icon flaticon2-phone text-primary"></i>
                      <label class="col-form-label ml-2">
                        {{ viewOrder.customerPhone }}
                      </label>
                    </div>

                    <div
                      v-if="viewOrder.customerMail"
                      class="d-inline-flex col-12 col-md-6 align-items-center"
                    >
                      <i class="menu-icon flaticon2-email text-primary"></i>
                      <label class="col-form-label ml-2">
                        {{ viewOrder.customerMail }}
                      </label>
                    </div>
                  </div>
                  <div class="roe">
                    <div
                      class="d-inline-flex col-12 flex-column align-items-center"
                    >
                      <template v-if="viewOrder.sourceAddress">
                        <label
                          class="col-form-label ml-2 col-12 font-weight-bolder"
                        >
                          {{
                            $t(
                              "PAGES.SALES_MANAGEMENT.ORDER_DETAILS.REFUND_WALLET_ADDRESS"
                            )
                          }}:
                        </label>
                        <label class="col-form-label ml-2 pt-0 mt-0 col-12">
                          {{ viewOrder.sourceAddress }}
                          <span
                            class="ml-1 btn btn-icon btn-sm"
                            @click="copyText(viewOrder.sourceAddress)"
                          >
                            <i class="fa fa-copy text-primary"></i>
                          </span>
                        </label>
                      </template>
                      <template v-if="viewOrder.txHash">
                        <label
                          class="col-form-label ml-2 col-12 font-weight-bolder"
                        >
                          TxId:
                        </label>
                        <label class="col-form-label ml-2 pt-0 mt-0 col-12">
                          <span class="txhash" @click="onClickTxHash">{{
                            viewOrder.txHash
                          }}</span>
                          <span
                            class="ml-1 btn btn-icon btn-sm"
                            @click="copyText(viewOrder.txHash)"
                          >
                            <i class="fa fa-copy text-primary"></i>
                          </span>
                        </label>
                      </template>
                    </div>
                  </div>
                </div>

                <div class="col-12 d-flex flex-row flex-wrap">
                  <div class="col-6">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t(
                          "PAGES.SALES_MANAGEMENT.LINK_SALES.TRANSACTION_STATUS"
                        )
                      }}:</label
                    >
                    <span
                      class="badge ml-2"
                      :class="[
                        getOrderBadgeAndLabel(viewOrder.status).itemClass
                      ]"
                    >
                      {{ getOrderBadgeAndLabel(viewOrder.status).itemLabel }}
                    </span>
                  </div>
                  <!-- <div class="col-6" v-if="isOrderStatusCompleted">
                    <div class="col-12 d-flex flex-row align-items-center">
                      <label class="col-form-label font-weight-bold">
                        {{ $t("COMMON.payment") }}
                      </label>
                      <b-form-checkbox
                        v-model="isFiat"
                        class="text-uppercase ml-3"
                        name="check-button"
                        size="lg"
                        switch
                        :disabled="true"
                      >
                        {{ isFiat ? "FIAT" : $t("PAGES.DASHBOARD.CRYPTO") }}
                      </b-form-checkbox>
                    </div>
                  </div> -->
                  <!-- <div class="col-6">
                    <label class="col-form-label font-weight-bold"
                      >{{
                        $t("PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATE")
                      }}:</label
                    >
                    <span
                      class="badge ml-2"
                      :class="[paymentStateClass(viewOrder.paymentStateId)]"
                    >
                      {{
                        $t(
                          `PAGES.SALES_MANAGEMENT.LIST.PAYMENT_STATES[${viewOrder.paymentStateId}]`
                        )
                      }}
                    </span>
                  </div> -->
                </div>
                <!-- <template v-if="showPaymentInfo && paymentInfo">
                  <div class="w-100 top-divider my-2"></div>
                  <div class="col-12 col-md-6 mt-5">
                    <h6>
                      {{ $t("COMPANY.domestic_transfer_infos") }}
                    </h6>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.bank") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.bankName ? paymentInfo.bankName : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.account_name") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.accountName ? paymentInfo.accountName : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.account_type") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.accountType ? paymentInfo.accountType : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >IBAN:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.iban ? paymentInfo.iban : "-"
                      }}</span>
                    </div>
                  </div>
                  <div class="col-12 col-md-6  mt-5">
                    <h6>
                      {{ $t("COMPANY.overseas_transfer_infos") }}
                    </h6>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.bank") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasBankName
                          ? paymentInfo.overseasBankName
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.buyer") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasBuyerInfo
                          ? paymentInfo.overseasBuyerInfo
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.city") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasCityInfo
                          ? paymentInfo.overseasCityInfo
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.account_no") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasAccountNo
                          ? paymentInfo.overseasAccountNo
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >{{ $t("COMPANY.swift_code") }}:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasSwiftCode
                          ? paymentInfo.overseasSwiftCode
                          : "-"
                      }}</span>
                    </div>
                    <div class="col-12">
                      <label class="col-form-label font-weight-bold"
                        >IBAN:</label
                      >
                      <span class="ml-2">{{
                        paymentInfo.overseasIbanNo
                          ? paymentInfo.overseasIbanNo
                          : "-"
                      }}</span>
                    </div>
                  </div>
                </template> -->
              </div>
            </div>
          </form>
          <div class="card-footer d-flex">
            <button
              class="btn btn-outline-primary ml-2"
              v-if="isLoadingOrderReceipt"
            >
              <b-icon icon="arrow-clockwise" animation="spin"></b-icon>
            </button>
            <template v-else-if="isFiatAndCompleted">
              <button
                class="btn btn-outline-primary ml-2"
                @click="$bvModal.show('file-drop-zone-modal')"
              >
                <i class="fa fa-file-upload"></i>
                {{
                  $t("PAGES.SALES_MANAGEMENT.ORDER_DETAILS.UPLOAD_BANK_RECEIPT")
                }}
              </button>
              <button
                class="btn btn-outline-success ml-2"
                v-if="orderHasReceipt"
                @click="downloadReceipt"
              >
                <i class="fa fa-file-download"></i>
                {{
                  $t(
                    "PAGES.SALES_MANAGEMENT.ORDER_DETAILS.DOWNLOAD_BANK_RECEIPT"
                  )
                }}
              </button>
            </template>
          </div>
        </div>
        <FileDropZone @onConfirm="onFileConfirmed" />
      </b-overlay>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";

import {
  getOrderBadgeAndLabel,
  getTotal,
  getPaymentUnitName
} from "@/utils/order-helpers";
import FileDropZone from "@/components/FileDropZone.vue";
import { paymentStateClass, getTxUrl } from "@/constants";
export default {
  components: {
    FileDropZone
  },
  data() {
    return {
      isOrderLoading: false,
      showOverlay: false,
      isLoadingOrderReceipt: false,
      orderHasReceipt: false,
      paymentInfo: null
    };
  },
  computed: {
    ...mapGetters({
      viewOrder: "orders/VIEW_ORDER"
    }),
    getOrderId() {
      return this.viewOrder?.id || this.viewOrder?._id;
    },
    isOrderStatusCompleted() {
      return `${this.viewOrder.status}` === "1";
    },
    isPaymentStatePending() {
      return `${this.viewOrder?.paymentStateId}` === "1";
    },
    isPaymentStateCompleted() {
      return `${this.viewOrder?.paymentStateId}` === "3";
    },
    isFiat() {
      return this.viewOrder?.fiat || false;
    },
    isFiatAndCompleted() {
      return this.isFiat && this.isPaymentStateCompleted;
    },
    showPaymentInfo() {
      const _paymentState =
        this.isPaymentStateCompleted || this.isPaymentStatePending;
      return this.isOrderStatusCompleted && this.isFiat && _paymentState;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SALES_MANAGEMENT") },
      { title: this.$t("ROUTES.SALE_DETAILS") }
    ]);
  },
  created() {
    this.fetchOrderDetails();
  },
  methods: {
    ...mapActions("orders", [
      "GET_ORDER",
      "GET_ORDER_RECEIPT",
      "DOWNLOAD_ORDER_RECEIPT",
      "UPLOAD_ORDER_RECEIPT"
    ]),
    async fetchOrderDetails() {
      this.isOrderLoading = true;
      await this.GET_ORDER(this.$route.params.orderId);
      this.isOrderLoading = false;
      this.getOrderReceipt();
      if (this.showPaymentInfo) {
        this.getPaymentInfo();
      }
    },
    getOrderBadgeAndLabel,
    getTotal,
    getPaymentUnitName,
    paymentStateClass,
    async copyText(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$root.$bvToast.toast(this.$t("FORM.SUCCESS_TITLE"), {
          title: this.$t("COMMON.INFORM"),
          variant: "success",
          autoHideDelay: 5000
        });
      } catch (error) {
        console.log("copyText error: ", error);
        this.$toast.error(this.$t("service.fatal_error"));
      }
    },
    onClickTxHash() {
      const txHash = this.viewOrder?.txHash;
      if (txHash) {
        // let link = `https://etherscan.io/tx/${txHash}`;  // eth transaction tx details
        /* const code = `${this.viewOrder.selectedCurrencyCode}`.toLowerCase();
        let link = `https://www.blockchain.com/explorer/transactions/${code}/${txHash}`; */
        const link = getTxUrl(this.viewOrder.selectedCurrencyCode, txHash);
        !!link && window.open(link, "_blank");
      }
    },
    async getOrderReceipt() {
      this.isLoadingOrderReceipt = true;
      try {
        const res = await this.GET_ORDER_RECEIPT(this.getOrderId);
        this.isLoadingOrderReceipt = false;
        this.orderHasReceipt = !!res.data?.data || false;
        return "success";
      } catch (error) {
        this.orderHasReceipt = false;
        this.isLoadingOrderReceipt = false;
        return error;
      }
    },
    async onFileConfirmed(file) {
      this.showOverlay = true;
      try {
        const formData = new FormData();
        formData.append("order", this.getOrderId);
        formData.append("file", file);
        await this.UPLOAD_ORDER_RECEIPT(formData);
        await this.getOrderReceipt();
        this.$root.$bvToast.toast(this.$t("FORM.SUBMITTED"), {
          title: this.$t("FORM.SUCCESS_TITLE"),
          variant: "success",
          autoHideDelay: 5000
        });
        this.showOverlay = false;
      } catch (error) {
        console.log("onFileConfirmed: ", error);
        this.showOverlay = false;
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    async downloadReceipt() {
      this.showOverlay = true;
      try {
        await this.DOWNLOAD_ORDER_RECEIPT(this.getOrderId);
        this.showOverlay = false;
      } catch (error) {
        this.showOverlay = false;
        console.log("downloadReceipt: ", error);
        this.$root.$bvToast.toast(this.$t("FORM.ERROR"), {
          title: this.$t("FORM.ERROR_TITLE"),
          variant: "danger",
          autoHideDelay: 5000
        });
      }
    },
    async getPaymentInfo() {
      try {
        const res = await this.$store.dispatch(
          "company/GET_PAYMENT_INFO_BY_MERCHANT",
          this.viewOrder.merchantId
        );
        this.paymentInfo = res?.data;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.txhash {
  color: cornflowerblue;
  &:hover {
    opacity: 0.8;
    color: darkgrey;
    text-decoration: underline;
    cursor: pointer;
  }
}
.top-divider {
  border-top: 1px solid #bababa;
}
</style>
