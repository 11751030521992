<template>
  <b-modal
    id="file-drop-zone-modal"
    @hidden="_onClose"
    @shown="file = null"
    centered
    modal-class="body-class"
  >
    <template #modal-header="{ close }">
      <h2
        class="m-0 w-100 text-primary w-100 text-center font-weight-bolder"
      ></h2>

      <button type="button" aria-label="Close" @click="close()" class="close">
        ×
      </button>
    </template>
    <div class="main">
      <div
        class="dropzone-container"
        @dragover="dragover"
        @dragleave="dragleave"
        @drop="drop"
      >
        <input
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          ref="file"
          accept=".pdf,.jpg,.jpeg,.png"
        />

        <label for="fileInput" class="file-label" v-if="!file">
          <div v-if="isDragging">
            {{ $t("COMMON.file_drop_zone_label_dragging") }}
          </div>
          <div v-html="$t('COMMON.file_drop_zone_label')" v-else></div>
        </label>

        <div class="preview-container" v-else>
          <i
            class="fa fa-file-pdf fa-3x"
            v-if="file.type === 'application/pdf'"
          ></i>
          <i class="fa fa-file-image fa-3x" v-else></i>
          <p :title="file.name">
            {{ file.name }}
          </p>

          <b-button
            variant="outline-danger"
            @click="remove"
            class="ml-2"
            size="sm"
            ><b>&times;</b> {{ $t("COMMON.remove") }}</b-button
          >
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="w-100 d-flex flex-column align-items-center">
        <b-button
          variant="primary"
          class="w-50"
          @click="_onConfirm"
          :disabled="!file"
          >{{ $t("FORM.CONFIRM") }}</b-button
        >
        <b-button
          variant="outline-secondary"
          class="mt-2 w-50"
          @click="_onClose"
          >{{ $t("FORM.CANCEL") }}</b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "FileDropZoneModal",
  data: () => ({
    file: null,
    isDragging: false
  }),
  methods: {
    _onClose() {
      this.$bvModal.hide("file-drop-zone-modal");
    },
    _onConfirm() {
      this.$emit("onConfirm", this.file);
      this._onClose();
    },
    onChange() {
      this.file = this.$refs.file.files[0];
      // console.log(this.$refs.file.files[0]);
    },
    remove() {
      this.file = null;
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.isDragging = false;
      this.file = e.dataTransfer.files[0];
    }
  }
};
</script>

<style lang="scss" scoped>
.main {
  padding: 50px 15px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.dropzone-container {
  padding: 4rem;
  background: #f7fafc;
  border: 1px solid #e2e8f0;
  width: 100%;
  height: auto;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
